import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PlayVideoComponent } from './play-video.component';

@NgModule({
  declarations: [PlayVideoComponent],
  imports: [CommonModule],
  exports: [PlayVideoComponent],
})
export class PlayVideoModule {}
