import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';

import { PlayVideoModule } from '../';
import { FilmCardViewComponent } from './film-card-view.component';

@NgModule({
  declarations: [FilmCardViewComponent],
  imports: [CommonModule, ModalModule.forRoot(), PlayVideoModule],
  exports: [FilmCardViewComponent],
})
export class FilmCardViewModule {}
