import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'film-card-view',
  templateUrl: './film-card-view.component.html',
  styleUrls: ['./film-card-view.component.scss'],
})
export class FilmCardViewComponent implements OnInit {
  @Input() dataSet: any = null;
  @Input() cardClass: string = '';
  @Input() viewType: string = 'fullDetails';

  modalRef: BsModalRef;
  environment: any = environment;
  cardDetails: any = {
    modelDetails: {},
  };

  constructor(private modalService: BsModalService) {}

  ngOnInit(): void {}

  openModal(template: TemplateRef<any>, dataSet) {
    this.cardDetails.modelDetails = dataSet;
    this.modalRef = this.modalService.show(template);
  }

  closeModel() {
    this.cardDetails.modelDetails = {};
    this.modalRef.hide();
  }
}
