import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'ngx-bootstrap/carousel';

import { FilmCardViewModule } from '../../';
import { HomeSliderComponent } from './home-slider.component';

@NgModule({
  declarations: [HomeSliderComponent],
  imports: [
    CommonModule,
    CarouselModule.forRoot(),
    FilmCardViewModule,
  ],
  exports: [HomeSliderComponent],
})
export class HomeSliderModule {}
