<iframe
  *ngIf="videoURL && videoURL !== ''"
  [class.thumbnail]="thumbnail"
  [src]="safeSrc"
  width="465"
  height="315"
  frameborder="0"
  webkitallowfullscreen
  mozallowfullscreen
  allowfullscreen
></iframe>
