import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { AuthGuard } from '../../services';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnInit {
  environment: any = environment;
  currentUrl: string = '/';
  userDetails: any = {};
  toggleBtn: boolean = false;

  constructor(private router: Router, private authGuard: AuthGuard) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.currentUrl = val.url;
      }
    });
  }

  ngOnInit(): void {
    this.authGuard.getUserDetails().subscribe((resp: any) => {
      if (resp) {
        this.userDetails = resp;
      }
    });
  }

  updateBtn() {
    this.toggleBtn = !this.toggleBtn;
    this.updateBodyClass();
  }

  redirectUrl(url) {
    this.toggleBtn = false;
    this.updateBodyClass();
    this.router.navigateByUrl(url);
  }

  updateBodyClass() {
    if (this.toggleBtn) {
      $('.body').addClass('body--active');
    } else {
      $('.body').removeClass('body--active');
    }
  }
}
