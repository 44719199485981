<nav class="sb-topnav navbar navbar-expand navbar-dark bg-dark">
  <a class="navbar-brand" [routerLink]="'/admin'"
    >{{ environment.projectName }}
  </a>
  <button
    class="btn btn-link btn-sm order-1 order-lg-0"
    id="sidebarToggle"
    href="javascript:void();"
  >
    <!-- <i class="fas fa-bars"></i> -->
  </button>
  <!-- Navbar Search-->
  <form
    class="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-3 my-2 my-md-0"
  >
    <div class="input-group">
      <!-- <input
        class="form-control"
        type="text"
        placeholder="Search for..."
        aria-label="Search"
        aria-describedby="basic-addon2"
      />
      <div class="input-group-append">
        <button class="btn btn-primary" type="button">
          <i class="fas fa-search"></i>
        </button>
      </div> -->
    </div>
  </form>
  <!-- Navbar-->
  <ul class="navbar-nav ml-auto ml-md-0">
    <li class="nav-item dropdown">
      <div class="btn-group" dropdown placement="bottom right">
        <button
          id="button-alignment"
          dropdownToggle
          type="button"
          class="btn btn-primary dropdown-toggle"
          aria-controls="dropdown-alignment"
        >
          <i class="fas fa-user fa-fw"></i> <span class="caret"></span>
        </button>
        <ul
          id="dropdown-alignment"
          *dropdownMenu
          class="dropdown-menu dropdown-menu-right"
          role="menu"
          aria-labelledby="button-alignment"
        >
          <li role="menuitem">
            <a class="dropdown-item" href="javascript:void();"
              >{{ userDetails.name }} ({{ userDetails.email }})</a
            >
          </li>
          <li class="divider dropdown-divider"></li>
          <li role="menuitem">
            <a
              class="dropdown-item"
              href="javascript:void();"
              (click)="logOut()"
              >Log Out</a
            >
          </li>
        </ul>
      </div>
      <!-- <a
        class="nav-link dropdown-toggle"
        id="userDropdown"
        href="#"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        ><i class="fas fa-user fa-fw"></i
      ></a>
      <div
        class="dropdown-menu dropdown-menu-right"
        aria-labelledby="userDropdown"
      >
        <a class="dropdown-item" href="#">Settings</a>
        <a class="dropdown-item" href="#">Activity Log</a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" href="login.html">Logout</a>
      </div> -->
    </li>
  </ul>
</nav>
<div id="layoutSidenav" class="bg-light">
  <div id="layoutSidenav_nav" class="themes-dark">
    <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
      <div class="sb-sidenav-menu">
        <div class="nav">
          <div class="sb-sidenav-menu-heading">
            <img src="assets/images/logo.jpeg" alt="" style="height: 50px;" />
          </div>
          <a class="nav-link" [routerLink]="['/home']">
            <div class="sb-nav-link-icon">
              <i class="fas fa-home"></i>
            </div>
            Home Site
          </a>
          <a class="nav-link" [routerLink]="['/admin/production']">
            <div class="sb-nav-link-icon">
              <i class="fas fa-film"></i>
            </div>
            Production
          </a>
          <a class="nav-link" [routerLink]="['/admin/catalog']">
            <div class="sb-nav-link-icon">
              <i class="fas fa-table"></i>
            </div>
            Catelog
          </a>
          <!-- <div class="sb-sidenav-menu-heading">Interface</div>
          <a
            class="nav-link collapsed"
            href="#"
            data-toggle="collapse"
            data-target="#collapseLayouts"
            aria-expanded="false"
            aria-controls="collapseLayouts"
          >
            <div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
            Layouts
            <div class="sb-sidenav-collapse-arrow">
              <i class="fas fa-angle-down"></i>
            </div>
          </a>
          <div
            class="collapse"
            id="collapseLayouts"
            aria-labelledby="headingOne"
            data-parent="#sidenavAccordion"
          >
            <nav class="sb-sidenav-menu-nested nav">
              <a class="nav-link" href="layout-static.html"
                >Static Navigation</a
              >
              <a class="nav-link" href="layout-sidenav-light.html"
                >Light Sidenav</a
              >
            </nav>
          </div>
          <a
            class="nav-link collapsed"
            href="#"
            data-toggle="collapse"
            data-target="#collapsePages"
            aria-expanded="false"
            aria-controls="collapsePages"
          >
            <div class="sb-nav-link-icon"><i class="fas fa-book-open"></i></div>
            Pages
            <div class="sb-sidenav-collapse-arrow">
              <i class="fas fa-angle-down"></i>
            </div>
          </a>
          <div
            class="collapse"
            id="collapsePages"
            aria-labelledby="headingTwo"
            data-parent="#sidenavAccordion"
          >
            <nav
              class="sb-sidenav-menu-nested nav accordion"
              id="sidenavAccordionPages"
            >
              <a
                class="nav-link collapsed"
                href="#"
                data-toggle="collapse"
                data-target="#pagesCollapseAuth"
                aria-expanded="false"
                aria-controls="pagesCollapseAuth"
              >
                Authentication
                <div class="sb-sidenav-collapse-arrow">
                  <i class="fas fa-angle-down"></i>
                </div>
              </a>
              <div
                class="collapse"
                id="pagesCollapseAuth"
                aria-labelledby="headingOne"
                data-parent="#sidenavAccordionPages"
              >
                <nav class="sb-sidenav-menu-nested nav">
                  <a class="nav-link" href="login.html">Login</a>
                  <a class="nav-link" href="register.html">Register</a>
                  <a class="nav-link" href="password.html">Forgot Password</a>
                </nav>
              </div>
              <a
                class="nav-link collapsed"
                href="#"
                data-toggle="collapse"
                data-target="#pagesCollapseError"
                aria-expanded="false"
                aria-controls="pagesCollapseError"
              >
                Error
                <div class="sb-sidenav-collapse-arrow">
                  <i class="fas fa-angle-down"></i>
                </div>
              </a>
              <div
                class="collapse"
                id="pagesCollapseError"
                aria-labelledby="headingOne"
                data-parent="#sidenavAccordionPages"
              >
                <nav class="sb-sidenav-menu-nested nav">
                  <a class="nav-link" href="401.html">401 Page</a>
                  <a class="nav-link" href="404.html">404 Page</a>
                  <a class="nav-link" href="500.html">500 Page</a>
                </nav>
              </div>
            </nav>
          </div>
          <div class="sb-sidenav-menu-heading">Addons</div>
          <a class="nav-link" href="charts.html">
            <div class="sb-nav-link-icon">
              <i class="fas fa-chart-area"></i>
            </div>
            Charts
          </a>
          <a class="nav-link" href="tables.html">
            <div class="sb-nav-link-icon"><i class="fas fa-table"></i></div>
            Tables
          </a> -->
        </div>
      </div>
      <div class="sb-sidenav-footer">
        <!-- <div class="small">Logged in as:</div>
        Start Bootstrap -->
      </div>
    </nav>
  </div>
  <div id="layoutSidenav_content">
    <main>
      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>
    </main>
    <footer class="py-4 bg-light mt-auto">
      <div class="container-fluid">
        <div class="d-flex align-items-center justify-content-between small">
          <div class="text-muted">Copyright &copy; Your Website 2020</div>
          <div>
            <a href="#">Privacy Policy</a>
            &middot;
            <a href="#">Terms &amp; Conditions</a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</div>
