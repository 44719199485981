<ng-container
  *ngIf="sliderDetails && sliderDetails.slides && sliderDetails.slides.length"
>
  <carousel
    [isAnimated]="true"
    [itemsPerSlide]="itemsPerSlide"
    [singleSlideOffset]="singleSlideOffset"
    [startFromIndex]="itemsPerSlide + 1"
    [interval]="false"
  >
    <slide *ngFor="let slide of sliderDetails.slides; let index = index">
      <film-card-view [dataSet]="slide" [viewType]="'details'" [cardClass]="'card--big'"></film-card-view>
    </slide>
  </carousel>
