import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { of, Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';

import { environment } from '../../../../environments/environment';

@Injectable()
export class AuthGuard implements CanActivate {
  key: string = environment.cryptoKey;

  constructor(
    private router: Router,
    private titleService: Title,
    private cookieService: CookieService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getUserDetails().subscribe((userDetails: any) => {
        if (userDetails && userDetails.id) {
          return resolve(true);
        } else {
          this.router.navigate(['/login']);
          return resolve(false);
        }
      });
    });
  }

  decodeKey(data) {
    const bytes = CryptoJS.AES.decrypt(data, this.key);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  encodeKey(data) {
    const encrypted = CryptoJS.AES.encrypt(data, this.key);
    return encrypted.toString();
  }

  decodeString(data: string) {
    return of(this.decodeKey(data));
  }

  encodeString(data: string) {
    return of(this.encodeKey(data));
  }

  getToken(id) {
    return this.cookieService.get(id);
  }

  setToken(id, data) {
    return this.cookieService.set(id, data);
  }

  deleteToken(id) {
    this.cookieService.delete(id);
  }

  getLocalStorage(id): Observable<any> {
    const details = localStorage.getItem(id);
    if (details && details !== '') {
      return this.decodeString(details);
    } else {
      return of('');
    }
  }

  setLocalStorage(id, data) {
    localStorage.setItem(id, this.encodeKey(data));
  }

  deleteLocalStorage(id) {
    localStorage.removeItem(id);
  }

  getUserDetails(): Observable<any> {
    return this.getLocalStorage(environment.localStorageKey.userDetails);
  }

  setLoginDetails(userDetails, token) {
    this.setLocalStorage(
      environment.localStorageKey.userDetails,
      JSON.stringify(userDetails)
    );
    const cookiesToken = this.setToken(environment.cookiesName.token, token);

    return of(true);
  }

  setLogOutApplication() {
    this.deleteLocalStorage(environment.localStorageKey.userDetails);
    this.deleteToken(environment.cookiesName.token);

    return of(true);
  }

  setConstants(constantData) {
    this.setLocalStorage(
      environment.localStorageKey.constants,
      JSON.stringify(constantData)
    );
  }

  getConstants() {
    return this.getLocalStorage(environment.localStorageKey.constants);
    //     .subscribe((resp: any) => {
    //       if (resp) {
    //         return resolve(resp);
    //       } else {
    //         this.getRequest(
    //           '/onlineexam/setting/apiconstant',
    //           []
    //         ).subscribe((cResp: any) => {
    //           const constantData = {
    //             constant: cResp.apiconstant,
    //             staticmsg: cResp.apistaticmsg,
    //           };
    //           this.authGuard.setLocalStorage(
    //             environment.localStorageKey.constants,
    //             JSON.stringify(constantData)
    //           );
    //           return resolve(constantData);
    //         });
    //       }
    //     });
    // });
  }
}
