<ng-container *ngIf="viewType === 'details'; else showFullDetails">
  <div class="card theme-bg-color {{ cardClass }}">
    <div class="card__cover">
      <img
        [src]="
          dataSet.img_path
            ? environment.assetUrl + dataSet.img_path
            : environment.assetUrl + 'assets/golden/noimg.jpg'
        "
        alt=""
      />
      <a
        href="javascript:void(0)"
        class="card__play"
        (click)="openModal(template, dataSet)"
      >
        <i class="icon ion-ios-play"></i>
      </a>
    </div>
    <div class="card__content">
      <h3 class="card__title">
        <a href="javascript:void(0)">{{ dataSet.title }}</a>
      </h3>
      <span class="card__category">
        <a
          href="javascript:void(0)"
          *ngFor="let categoryType of dataSet.categoryType"
          >{{ categoryType }}</a
        >
      </span>
      <span class="card__rate"
        ><i class="icon ion-ios-star"></i>{{ dataSet.rating }}</span
      >
    </div>
  </div>
</ng-container>

<ng-template #showFullDetails>
  <div class="card card--list theme-bg-color {{ cardClass }}">
    <div class="row">
      <div class="col-12 col-sm-4">
        <div class="card__cover">
          <img
            [src]="
              dataSet.img_path
                ? environment.assetUrl + dataSet.img_path
                : environment.assetUrl + 'assets/golden/noimg.jpg'
            "
            alt=""
          />
          <a
            href="javascript:void(0)"
            class="card__play"
            (click)="openModal(template, dataSet)"
          >
            <i class="icon ion-ios-play"></i>
          </a>
        </div>
      </div>

      <div class="col-12 col-sm-8">
        <div class="card__content">
          <h3 class="card__title">
            <a href="javascript:void(0)">{{ dataSet.title }}</a>
          </h3>
          <span class="card__category">
            <a
              href="javascript:void(0)"
              *ngFor="let categoryType of dataSet.categoryType"
              >{{ categoryType }}</a
            >
          </span>

          <div class="card__wrap">
            <span class="card__rate"
              ><i class="icon ion-ios-star"></i>{{ dataSet.rating }}</span
            >

            <!-- <ul class="card__list">
            <li>HD</li>
            <li>16+</li>
          </ul> -->
          </div>

          <div class="card__description">
            <p>{{ dataSet.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      {{ cardDetails.modelDetails.title }}
    </h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="closeModel()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <play-video [videoURL]="cardDetails.modelDetails.youtube_path"></play-video>
  </div>
</ng-template>
