import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { AuthGuard } from '../../services';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  environment: any = environment;
  userDetails: any = {};

  constructor(private authGuard: AuthGuard, private router: Router) {}

  ngOnInit(): void {
    this.authGuard.getUserDetails().subscribe((resp: any) => {
      if (resp) {
        this.userDetails = resp;
      }
    });
  }

  logOut() {
    this.authGuard.setLogOutApplication().subscribe((resp: any) => {
      if (resp) {
        this.router.navigate(['/login']);
      }
    });
  }
}
