import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BaseComponent, AdminComponent } from './shared/layouts';
import { AuthGuard } from './shared/services';

const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    // canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home',
        loadChildren: () =>
          import('./pages/home-pages/home/home.module').then(
            (m) => m.HomeModule
          ),
      },
      {
        path: 'catalog',
        loadChildren: () =>
          import('./pages/home-pages/category/category.module').then(
            (m) => m.CategoryModule
          ),
      },
      {
        path: 'about-us',
        loadChildren: () =>
          import('./pages/home-pages/about-us/about-us.module').then(
            (m) => m.AboutUsModule
          ),
      },
    ],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'production', pathMatch: 'full' },
      {
        path: 'production',
        loadChildren: () =>
          import('./pages/admin-pages/production/production.module').then(
            (m) => m.ProductionModule
          ),
      },
      {
        path: 'catalog',
        loadChildren: () =>
          import('./pages/admin-pages/catalog/catalog.module').then(
            (m) => m.CatalogModule
          ),
      },
    ],
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./pages/error-page/error-page.module').then(
        (m) => m.ErrorPageModule
      ),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./pages/error-page/error-page.module').then(
        (m) => m.ErrorPageModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
