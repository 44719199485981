import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';

import { CommonService } from '../../../shared/services';

@Component({
  selector: 'home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.scss'],
})
export class HomeSliderComponent implements OnInit {
  itemsPerSlide = 4;
  singleSlideOffset = true;

  sliderDetails: any = {
    allCategory: [],
    slides: [],
    modelDetails: {},
  };

  constructor(private commonService: CommonService) {}

  ngOnInit(): void {
    forkJoin([
      this.commonService.getRequest('/golden/categorylist', ['showAll=true']),
      this.commonService.getRequest('/golden/filmlist/4', []),
    ]).subscribe((resp: any) => {
      if (resp && resp[0].status) {
        this.sliderDetails.allCategory = resp[0].results;
      }

      if (resp && resp[1].status) {
        this.sliderDetails.slides = resp[1].results.map((r) => {
          let categoryType = [];
          if (r['category_type'] && r['category_type'] !== '') {
            let type = r['category_type'].split(',');

            type.forEach((t) => {
              this.sliderDetails.allCategory.forEach((c) => {
                if (c && c['id'] && c['id'] === t) {
                  categoryType.push(c['name']);
                }
              });
            });
          }

          return {
            img_path: r.img_path,
            title: r.title,
            categoryType: categoryType,
            rating: r.rating,
            youtube_path: r.youtube_path,
          };
        });
      }
    });
  }
}
