import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpResponse,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { AuthGuard } from './auth.guard';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
  constructor(private authGuard: AuthGuard, private router: Router) {}

  // intercept request and add token
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const details = this.authGuard.getToken(environment.cookiesName.token);
    if (details && details !== '') {
      request = request.clone({
        setHeaders: {
          token: details,
        },
      });
    }
    return next.handle(request).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            if (
              event &&
              event.body &&
              event.body.error &&
              event.body.error === 'loginUser'
            ) {
              this.authGuard.setLogOutApplication().subscribe((resp: any) => {
                if (resp) {
                  this.router.navigateByUrl('/');
                }
              });
            }
          }
        },
        (error) => {
          // http response status code
          // console.log('----response----');
          // console.error('status code:');
          // tslint:disable-next-line:no-debugger
          console.error(error.status);
          console.error(error.message);
          // console.log('--- end of response---');
        }
      )
    );
  }
}
