<!-- header -->
<header class="header">
  <div class="header__wrap">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="header__content">
            <!-- header logo -->
            <a routerLink="/" class="header__logo">
              <img src="assets/images/logo.jpeg" alt="" />
              <!-- {{ environment.projectName }} -->
            </a>
            <!-- end header logo -->

            <!-- header nav -->
            <ul
              class="header__nav"
              [ngClass]="{ 'header__nav--active': toggleBtn }"
            >
              <li class="header__nav-item">
                <a
                  href="javascript:void(0)"
                  (click)="redirectUrl('/')"
                  class="header__nav-link"
                  >Home</a
                >
              </li>

              <li class="header__nav-item">
                <a
                  href="javascript:void(0)"
                  (click)="redirectUrl('/catalog')"
                  class="header__nav-link"
                  >Category</a
                >
              </li>

              <!-- dropdown -->
              <!-- <li
                class="header__nav-item"
                *ngIf="categoryMenu && categoryMenu.length"
              > -->
              <!-- <div class="btn-group" dropdown>
                  <button
                    id="button-animated"
                    dropdownToggle
                    type="button"
                    class="header__nav-link dropdown-toggle"
                    aria-controls="dropdown-animated"
                  >
                    Category <span class="caret"></span>
                  </button>
                  <ul
                    id="dropdown-animated"
                    *dropdownMenu
                    class="dropdown-menu header__dropdown-menu"
                    role="menu"
                    aria-labelledby="button-animated"
                  >
                    <li role="menuitem" *ngFor="let category of categoryMenu">
                      <a
                        class="dropdown-item"
                        [routerLink]="'/category/list/' + category.id"
                      >
                        {{ category.name }}
                      </a>
                    </li>
                  </ul>
                </div> -->
              <!-- <div class="btn-group" dropdown>
                  <button
                    id="button-animated"
                    dropdownToggle
                    type="button"
                    class="header__nav-link dropdown-toggle"
                    aria-controls="dropdown-animated"
                  >
                    Category <span class="caret"></span>
                  </button>
                  <ul
                    id="dropdown-animated"
                    *dropdownMenu
                    class="dropdown-menu header__dropdown-menu"
                    aria-labelledby="button-animated"
                  >
                    <li>
                      <a class="dropdown-item" href="#">Action</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">Another action</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">Something else here Something else here Something else here</a>
                    </li>
                  </ul>
                </div> -->
              <!-- <a
                  class="dropdown-toggle header__nav-link"
                  href="#"
                  role="button"
                  id="dropdownMenuCatalog"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  >Category</a
                >

                <ul
                  class="dropdown-menu header__dropdown-menu"
                  aria-labelledby="dropdownMenuCatalog"
                >
                  <li><a href="catalog1.html">Catalog Grid</a></li>
                  <li><a href="catalog2.html">Catalog List</a></li>
                  <li><a href="details1.html">Details Movie</a></li>
                  <li><a href="details2.html">Details TV Series</a></li>
                </ul> -->
              <!-- </li> -->
              <!-- end dropdown -->

              <li class="header__nav-item">
                <a
                  href="javascript:void(0)"
                  (click)="redirectUrl('/about-us')"
                  class="header__nav-link"
                  >About Us</a
                >
              </li>

              <!-- <li class="header__nav-item">
                <a href="faq.html" class="header__nav-link">Help</a>
              </li> -->

              <!-- dropdown -->
              <!-- <li class="dropdown header__nav-item">
                <a
                  class="dropdown-toggle header__nav-link header__nav-link--more"
                  href="#"
                  role="button"
                  id="dropdownMenuMore"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  ><i class="icon ion-ios-more"></i
                ></a>

                <ul
                  class="dropdown-menu header__dropdown-menu"
                  aria-labelledby="dropdownMenuMore"
                >
                  <li><a href="about.html">About</a></li>
                  <li><a href="signin.html">Sign In</a></li>
                  <li><a href="signup.html">Sign Up</a></li>
                  <li><a href="404.html">404 Page</a></li>
                </ul>
              </li> -->
              <!-- end dropdown -->
            </ul>
            <!-- end header nav -->

            <!-- header auth -->
            <div class="header__auth">
              <!-- <button class="header__search-btn" type="button">
                <i class="icon ion-ios-search"></i>
              </button> -->

              <a
                href="javascript:void();"
                class="header__sign-in"
                routerLink="/admin"
                *ngIf="
                  userDetails && userDetails.name && userDetails.name !== ''
                "
              >
                <span>{{ userDetails.name }} ({{ userDetails.email }})</span>
              </a>
            </div>
            <!-- end header auth -->

            <!-- header menu btn -->
            <button
              class="header__btn"
              [ngClass]="{ 'header__btn--active': toggleBtn }"
              type="button"
              (click)="updateBtn()"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
            <!-- end header menu btn -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- header search -->
  <form action="#" class="header__search">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="header__search-content">
            <input
              type="text"
              placeholder="Search for a movie, TV Series that you are looking for"
            />

            <button type="button">search</button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <!-- end header search -->
</header>
<!-- end header -->

<!-- home -->
<section
  class="home"
  *ngIf="currentUrl === '/' || currentUrl === '/home'; else showTitle"
>
  <!-- home bg -->
  <div class="owl-carousel home__bg">
    <home-slider></home-slider>
    <!-- <div class="item home__cover" data-bg="assets/img/home/home__bg.jpg"></div>
    <div class="item home__cover" data-bg="assets/img/home/home__bg2.jpg"></div>
    <div class="item home__cover" data-bg="assets/img/home/home__bg3.jpg"></div>
    <div class="item home__cover" data-bg="assets/img/home/home__bg4.jpg"></div> -->
  </div>
  <!-- end home bg -->

  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1 class="home__title"><b>NEW ITEMS</b> OF THIS SEASON</h1>

        <!-- <button class="home__nav home__nav--prev" type="button">
          <i class="icon ion-ios-arrow-round-back"></i>
        </button>
        <button class="home__nav home__nav--next" type="button">
          <i class="icon ion-ios-arrow-round-forward"></i>
        </button> -->
      </div>

      <div class="col-12 mt-5">
        <home-slider></home-slider>
      </div>
    </div>
  </div>
</section>
<!-- end home -->

<ng-template #showTitle>
  <section
    class="section section--first section--bg"
    style="
      background: url('assets/images/section.jpg') center center / cover
        no-repeat;
    "
  >
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="section__wrap">
            <!-- section title -->
            <h2 class="section__title" *ngIf="currentUrl.includes('/about-us')">
              About Us
            </h2>
            <h2 class="section__title" *ngIf="currentUrl.includes('/catalog')">
              Catalog
            </h2>
            <!-- end section title -->

            <!-- breadcrumb -->
            <ul class="breadcrumb">
              <li class="breadcrumb__item"><a href="/">Home</a></li>
              <li
                class="breadcrumb__item breadcrumb__item--active"
                *ngIf="currentUrl.includes('/about-us')"
              >
                About Us
              </li>
              <li
                class="breadcrumb__item breadcrumb__item--active"
                *ngIf="currentUrl.includes('/catalog')"
              >
                Catalog
              </li>
            </ul>
            <!-- end breadcrumb -->
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-template>

<router-outlet></router-outlet>

<!-- partners -->
<section class="section">
  <div class="container">
    <div class="row">
      <!-- section title -->
      <div class="col-12">
        <h2 class="section__title section__title--no-margin">Our Partners</h2>
      </div>
      <!-- end section title -->

      <!-- section text -->
      <div class="col-12">
        <p class="section__text section__text--last-with-margin">
          It is a long <b>established</b> fact that a reader will be distracted
          by the readable content of a page when looking at its layout. The
          point of using Lorem Ipsum is that it has a more-or-less normal
          distribution of letters, as opposed to using.
        </p>
      </div>
      <!-- end section text -->

      <!-- partner -->
      <div class="col-6 col-sm-4 col-md-3 col-lg-2">
        <a href="#" class="partner">
          <img
            src="assets/images/partners/themeforest-light-background.png"
            alt=""
            class="partner__img"
          />
        </a>
      </div>
      <!-- end partner -->

      <!-- partner -->
      <div class="col-6 col-sm-4 col-md-3 col-lg-2">
        <a href="#" class="partner">
          <img
            src="assets/images/partners/audiojungle-light-background.png"
            alt=""
            class="partner__img"
          />
        </a>
      </div>
      <!-- end partner -->

      <!-- partner -->
      <div class="col-6 col-sm-4 col-md-3 col-lg-2">
        <a href="#" class="partner">
          <img
            src="assets/images/partners/codecanyon-light-background.png"
            alt=""
            class="partner__img"
          />
        </a>
      </div>
      <!-- end partner -->

      <!-- partner -->
      <div class="col-6 col-sm-4 col-md-3 col-lg-2">
        <a href="#" class="partner">
          <img
            src="assets/images/partners/photodune-light-background.png"
            alt=""
            class="partner__img"
          />
        </a>
      </div>
      <!-- end partner -->

      <!-- partner -->
      <div class="col-6 col-sm-4 col-md-3 col-lg-2">
        <a href="#" class="partner">
          <img
            src="assets/images/partners/activeden-light-background.png"
            alt=""
            class="partner__img"
          />
        </a>
      </div>
      <!-- end partner -->

      <!-- partner -->
      <div class="col-6 col-sm-4 col-md-3 col-lg-2">
        <a href="#" class="partner">
          <img
            src="assets/images/partners/3docean-light-background.png"
            alt=""
            class="partner__img"
          />
        </a>
      </div>
      <!-- end partner -->
    </div>
  </div>
</section>
<!-- end partners -->

<!-- footer -->
<footer class="footer">
  <div class="container">
    <div class="row">
      <!-- footer list -->
      <!-- <div class="col-12 col-md-3">
        <h6 class="footer__title">Download Our App</h6>
        <ul class="footer__app">
          <li>
            <a href="#"
              ><img src="assets/img/Download_on_the_App_Store_Badge.svg" alt=""
            /></a>
          </li>
          <li>
            <a href="#"
              ><img src="assets/img/google-play-badge.png" alt=""
            /></a>
          </li>
        </ul>
      </div> -->
      <!-- end footer list -->

      <!-- footer list -->
      <div class="col-6 col-sm-4 col-md-4">
        <h6 class="footer__title">Resources</h6>
        <ul class="footer__list">
          <li><a href="#">About Us</a></li>
          <li><a href="#">Pricing Plan</a></li>
          <li><a href="#">Help</a></li>
        </ul>
      </div>
      <!-- end footer list -->

      <!-- footer list -->
      <div class="col-6 col-sm-4 col-md-4">
        <h6 class="footer__title">Legal</h6>
        <ul class="footer__list">
          <li><a href="#">Terms of Use</a></li>
          <li><a href="#">Privacy Policy</a></li>
          <li><a href="#">Security</a></li>
        </ul>
      </div>
      <!-- end footer list -->

      <!-- footer list -->
      <div class="col-12 col-sm-4 col-md-4">
        <h6 class="footer__title">Contact</h6>
        <ul class="footer__list">
          <li><a href="tel:+18002345678">+1 (800) 234-5678</a></li>
          <li><a href="mailto:support@moviego.com">support@flixgo.com</a></li>
        </ul>
        <ul class="footer__social">
          <li class="facebook">
            <a href="#"><i class="icon ion-logo-facebook"></i></a>
          </li>
          <li class="instagram">
            <a href="#"><i class="icon ion-logo-instagram"></i></a>
          </li>
          <li class="twitter">
            <a href="#"><i class="icon ion-logo-twitter"></i></a>
          </li>
          <li class="vk">
            <a href="#"><i class="icon ion-logo-vk"></i></a>
          </li>
        </ul>
      </div>
      <!-- end footer list -->

      <!-- footer copyright -->
      <div class="col-12">
        <div class="footer__copyright">
          <small
            ><a target="_blank" href="https://www.templateshub.net"
              >Templates Hub</a
            ></small
          >

          <ul>
            <li><a href="#">Terms of Use</a></li>
            <li><a href="#">Privacy Policy</a></li>
          </ul>
        </div>
      </div>
      <!-- end footer copyright -->
    </div>
  </div>
</footer>
<!-- end footer -->
