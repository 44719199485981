import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'play-video',
  templateUrl: './play-video.component.html',
  styleUrls: ['./play-video.component.scss'],
})
export class PlayVideoComponent implements OnInit {
  @Input() videoURL: string = null;

  safeSrc: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (this.videoURL && this.videoURL !== '') {
      this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.videoURL
      );
      console.log('this.videoURL', this.videoURL)
    }
  }
}
