import { Injectable } from '@angular/core';
import { of, forkJoin } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private BASE_URL = environment.apiHost;
  private httpOptions = {};

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    };
  }

  getRequest(url, parameter) {
    let apiUrl = '';
    let parameterFormat = '';
    if (parameter.length) {
      parameterFormat = '?' + parameter.join('&');
    }

    return this.http
      .get(this.BASE_URL + apiUrl + url + parameterFormat, this.httpOptions)
      .pipe(catchError(this.handleError<String>('getRequest->' + url)));
  }

  postRequest(url, parameter) {
    let apiUrl = '';
    let parameterFormat = '';
    if (parameter.length) {
      parameterFormat = parameter.join('&');
    }

    return this.http
      .post(this.BASE_URL + apiUrl + url, parameterFormat, this.httpOptions)
      .pipe(catchError(this.handleError<String>('postRequest->' + url)));
  }

  multiRequest(parellReq: Array<any>) {
    return parellReq && parellReq.length ? forkJoin(parellReq) : of([]);
  }

  checkActionable() {}

  checkSite(url, parameter) {
    let parameterFormat = '';
    if (parameter.length) {
      parameterFormat = '?' + parameter.join('&');
    }

    return this.http
      .get(url + parameterFormat)
      .pipe(catchError(this.handleError<String>('checkSite->' + url)));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any) => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
