// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  projectName: 'Golden Canvas Films',
  apiHost: 'http://localhost/cityappapi/index.php',
  assetUrl: 'http://localhost/cityappapi/',

  authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
  cryptoKey: 'praveenKanchanSaxena',
  localStorageKey: {
    layoutConfig: 'layoutConfig',
    userDetails: 'userDetails',
    constants: 'constants',
    token: 'token',
  },
  cookiesName: {
    childDetails: 'childDetails',
    siteId: 'sid',
    token: 'token',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
